import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { APIErrorDetails } from "#shared/components/api/errors";
import { BriefsApi } from "#src/apis/briefs/api";
import completeRegistration from "#src/apis/user/completeRegistration";
import fetchUserEngagement from "#src/apis/user/fetchUserEngagement";
import { getChannelUrl } from "#src/components/routing/utils";
import { HTTP_STATUS, ERROR_TYPES } from "#src/constants/common";
import { setErrors } from "#src/features/errors/errorsSlice";
import { setUserEngagement } from "#src/features/userEngagement/userEngagementSlice";
import { RootState } from "../../store";
import { ScreenerSubmissionState } from "../../types/state";
import hasProfileDetails from "../../utils/hasProfileDetails";
import { setAction } from "../action/actionSlice";

const initialState = {} as ScreenerSubmissionState;

const screenerSubmissionSlice = createSlice({
  name: "screenerSubmission",
  initialState,
  reducers: {
    submission(state, action: PayloadAction<ScreenerSubmissionState>) {
      state = action.payload;
      return state;
    },
  },
});

export const dispatchSubmissions = createAsyncThunk<
  void,
  { fingerprintId: string; briefRef: string }
>("screener/submission", async ({ fingerprintId, briefRef }, thunkAPI) => {
  const usersEngagements = await fetchUserEngagement(fingerprintId, briefRef);
  const userEngagement = usersEngagements?.length ? usersEngagements[0] : null;
  if (userEngagement && userEngagement?.answers) {
    thunkAPI.dispatch(setUserEngagement(userEngagement));
    const { user } = thunkAPI.getState() as RootState;
    // if user already logged in => submit poll responses
    if (user.isLoggedIn && user.token && hasProfileDetails(user.profile)) {
      const response = await completeRegistration({
        fingerprintId,
        briefRef,
        origin: "complete screener through server (already logged in)",
        cookies: { user: user.token },
      });
      if (response && "status" in response && response.status === HTTP_STATUS.OK) {
        const brief = await BriefsApi.fetchBriefDetails({ briefRef });
        const channelRef = brief ? brief.channelRef : "";
        const path = channelRef ? getChannelUrl(channelRef) : "/feed";
        // redirect if user already has a profile and is logged in
        thunkAPI.dispatch(
          setAction({
            redirectTo: `${path}?successRef=${briefRef}&fingerprintId=${fingerprintId}`,
          })
        );
      } else {
        thunkAPI.dispatch(
          setErrors({
            type: ERROR_TYPES.submit_poll_responses_fail,
            details: response as unknown as APIErrorDetails,
          })
        );
      }
    }
  } else {
    thunkAPI.dispatch(
      setErrors({
        type: ERROR_TYPES.user_engagement_not_found,
      })
    );
    console.log(
      `We could not find responses submitted in the screener for brief ${briefRef}`
    );
  }
});

export default screenerSubmissionSlice.reducer;
export const { submission } = screenerSubmissionSlice.actions;
